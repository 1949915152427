<template>
  <div class="addPage">
    <!-- 顶部title -->
    <div class="top">
      <h2>车辆认证信息</h2>
      <div>
        <el-button size="medium" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" size="medium" @click="save('confirmInfoForm')">保存</el-button>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="baseInfo">
      <div class="row">
        <p class="left">车牌号</p>
        <p>{{baseInfo.VehicleCarNumber}}</p>
      </div>
      <div class="row">
        <p class="left">道路运输证号</p>
        <p>{{baseInfo.VehicleRTP}}</p>
      </div>
      <div class="row">
        <p class="left">车辆类型</p>
        <p>{{baseInfo.VehicleType}}</p>
      </div>
      <div class="row">
        <p class="left">牌照类型</p>
        <p>{{baseInfo.CarNumberType}}</p>
      </div>
      <div class="row">
        <p class="left">所有人</p>
        <p>{{baseInfo.VehicleMaster}}</p>
      </div>
      <div class="row">
        <p class="left">归属类型</p>
        <p>{{baseInfo.VehicleAttribution}}</p>
      </div>
      <div class="row">
        <p class="left">运输证有效期至</p>
        <p>{{baseInfo.VehicleRTPDate}}</p>
      </div>
      <div class="row">
        <p class="left">认证状态</p>
        <p>{{baseInfo.VehicleRTPDate}}</p>
      </div>
      <div class="row">
        <p class="left">认证失败原因</p>
        <p>{{baseInfo.Remark}}</p>
      </div>
    </div>
    <hr>
    <!-- 上传证件照区域 -->
    <div class="uploadArea">
      <el-form class="confirmInfoForm" :model="confirmInfoForm" :rules="confirmInfoRule" ref="confirmInfoForm" label-width="160px">
        <el-form-item label="车辆行驶证(正页)" prop="vehicleFace.picUrl">
          <UploadImg :loading="confirmInfoForm.vehicleFace.loading" :uploaded="confirmInfoForm.vehicleFace.uploaded" :picUrl="confirmInfoForm.vehicleFace.picUrl" @update:file="updateFile" @change="uploadVehicleFace(confirmInfoForm.vehicleFace)" @deleteImg="deleteImg(confirmInfoForm.vehicleFace)"></UploadImg>
        </el-form-item>
        <el-form-item label="车辆行驶证副页(正面)" prop="vehicleBack.picUrl">
          <UploadImg :loading="confirmInfoForm.vehicleBack.loading" :uploaded="confirmInfoForm.vehicleBack.uploaded" :picUrl="confirmInfoForm.vehicleBack.picUrl" @update:file="updateFile" @change="uploadVehicleBack(confirmInfoForm.vehicleBack)" @deleteImg="deleteImg(confirmInfoForm.vehicleBack)"></UploadImg>
        </el-form-item>
        <el-form-item label="车辆行驶证副页(反面)" prop="vehiclePageOn.picUrl">
          <UploadImg :loading="confirmInfoForm.vehiclePageOn.loading" :uploaded="confirmInfoForm.vehiclePageOn.uploaded" :picUrl="confirmInfoForm.vehiclePageOn.picUrl" @update:file="updateFile" @change="uploadVehiclePageOn(confirmInfoForm.vehiclePageOn)" @deleteImg="deleteImg(confirmInfoForm.vehiclePageOn)"></UploadImg>
        </el-form-item>
        <el-form-item label="道路运输经营许可证" prop="vehicleRTP.picUrl">
          <UploadImg :loading="confirmInfoForm.vehicleRTP.loading" :uploaded="confirmInfoForm.vehicleRTP.uploaded" :picUrl="confirmInfoForm.vehicleRTP.picUrl" @update:file="updateFile" @change="uploadVehicleRTP(confirmInfoForm.vehicleRTP)" @deleteImg="deleteImg(confirmInfoForm.driverIDCardPhoto)"></UploadImg>
        </el-form-item>
        <!-- <el-form-item label="车头/车尾照片(有车牌)" prop="driverIDCardPhoto.picUrl">
          <UploadImg :loading="confirmInfoForm.driverIDCardPhoto.loading" :uploaded="confirmInfoForm.driverIDCardPhoto.uploaded" :picUrl="confirmInfoForm.driverIDCardPhoto.picUrl" @update:file="updateFile" @change="uploadHandIdCard(confirmInfoForm.driverIDCardPhoto)" @deleteImg="deleteImg(confirmInfoForm.driverIDCardPhoto)"></UploadImg>
        </el-form-item>
        <el-form-item label="车身照片(侧面)" prop="driverIDCardPhoto.picUrl">
          <UploadImg :loading="confirmInfoForm.driverIDCardPhoto.loading" :uploaded="confirmInfoForm.driverIDCardPhoto.uploaded" :picUrl="confirmInfoForm.driverIDCardPhoto.picUrl" @update:file="updateFile" @change="uploadHandIdCard(confirmInfoForm.driverIDCardPhoto)" @deleteImg="deleteImg(confirmInfoForm.driverIDCardPhoto)"></UploadImg>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { addVehicleInfo } from '@/api/platform/carManage/index'
import { getVehicleInfo, uploadVehicleFace, uploadVehicleBack, uploadVehiclePageOn, uploadVehicleRTP } from '@/api/platform/carManage/verify'
export default {
  data() {
    return {
      id: '', //车辆信息唯一标识
      currentFile: '', //当前正在操作的文件
      baseInfo: {}, //基本信息
      confirmInfo: {}, //证件信息
      confirmInfoForm: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: '',
          file: ''
        },
      },
      confirmInfoRule: {
        'vehicleFace.picUrl': [{ required: true, message: '请上传车辆行驶证(正页)', trigger: 'change' }],
        'vehicleBack.picUrl': [{ required: true, message: '请上传车辆行驶证副页(正面)', trigger: 'change' }],
        'vehiclePageOn.picUrl': [{ required: true, message: '请上传车辆行驶证副页(反面)', trigger: 'change' }],
        'vehicleRTP.picUrl': [{ required: true, message: '请上传道路运输经营许可证', trigger: 'change' }],
      },
    }
  },
  methods: {
    //获取车辆信息
    getVehicleInfo() {
      getVehicleInfo({ id: this.id }).then(res => {
        this.baseInfo = res.baseInfo
        this.confirmInfo = res.confirmInfo
        this.confirmInfoForm.vehicleFace.picUrl = res.confirm.VehicleLicenseFrontPageURL
        this.confirmInfoForm.vehicleBack.picUrl = res.confirm.VehicleLicenseSubPageBackURL
        this.confirmInfoForm.vehiclePageOn.picUrl = res.confirm.VehicleLicenseSubPageOnURL
        this.confirmInfoForm.vehicleRTP.picUrl = res.confirm.VehicleRTPUTL

      })
    },
    //上传行驶证正页
    uploadVehicleFace(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleFace(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.vehicles.vehicleFaceUrl
      }).catch(() => { })
        .finally(() => {
          field.loading = true
          field.uploaded = true
        })
    },
    //上传行驶证副页正面照
    uploadVehicleBack(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleBack(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.vehicles.vehicleBackUrl
      }).catch(() => { })
        .finally(() => {
          field.loading = true
          field.uploaded = true
        })
    },
    //上传行驶证副页反面照
    uploadVehiclePageOn(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehiclePageOn(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.vehicles
      }).catch(() => { })
        .finally(() => {
          field.loading = true
          field.uploaded = true
        })
    },
    //上传车辆道路运输证照片
    uploadVehicleRTP(field) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      uploadVehicleRTP(formData).then(res => {
        field.loading = false
        field.uploaded = true
        field.picUrl = res.vehicles
      }).catch(() => { })
        .finally(() => {
          field.loading = true
          field.uploaded = true
        })
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false
      field.loading = false
      field.picUrl = ''
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file
    },
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseinfo: [this.baseInfo],
            confirmInfo: []
          }
          params.confirmInfo.push({
            vehicleID: this.id,
            vehicleLicenseFrontPageURL:this.confirmInfoForm.vehicleFace.picUrl,
            vehicleLicenseSubPageOnURL:this.confirmInfoForm.vehicleBack.picUrl,
            vehicleLicenseSubPageBackURL:this.confirmInfoForm.vehiclePageOn.picUrl,
            vehicleRTPUTL:this.confirmInfoForm.vehicleRTP.picUrl
          })
          addVehicleInfo(params).then(res => {
            this.$router.go(-1)
          })
        }
      })
    }
  },
  created() {
    this.id = this.$route.query.id
    //获取车辆基本信息
    this.getVehicleInfo()
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/variable.scss';
.addPage {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .baseInfo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      .left {
        margin-right: 20px;
      }
    }
  }
  .uploadArea {
    margin-top: 20px;
    .confirmInfoForm {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>